var packing;
var packing_stack;
var body;
var header;
var last_scroll;
var grid;
var project_microstories;
var project_microstories_swipe;
var project_microstory_dots;
var projects_search_field;
var projects_search_field_input;
var videos;
var video_containers;
var vimeo_videos;
var video_buttons_hidden;
var last_move;
var scroll_top;
var vh;
var s0;
var s1;
var infinite_scroll_content;
var infinite_scroll_pages;
var infinite_scroll_url;
var infinite_scroll_page;
var infinite_scroll_trigger_percentage;
var infinite_scroll_loading;

function init(){
  document.addEventListener('turbolinks:load', pageInit);
}

document.addEventListener("DOMContentLoaded", function(){
  init();
});

function pageInit(){
  window.setupSort = setupSort;
  window.packGrid = packGrid;
  window.applyProjectFilters = applyProjectFilters;
  window.packing_stack = [];

  packing = true;
  body = $('body');
  header = $('header');
  last_scroll = 0;
  
  $('#insights_grid').imagesLoaded(function(){
    grid = $('#insights_grid');
    grid.on('layoutComplete', packingFinished);
    grid.packery({
      itemSelector: '.insight_thumbnail',
      transitionDuration: 0
    });
  });
  setupSlideshow();
  setupProjects();
  setupSort();
  setupGoogleMaps();
  setupVideos();
  setupMircostorySwipe();
  $('#footer_newsletter_trigger').on('click', toggleNewsletter);
  $('#newsletter_email').on('keyup', submitNewsletter);
  setupInfiniteScroll();
}


function packGrid(){
  if (packing){
    setTimeout(packGrid, 200);
    return;
  }
  packing = true;
  if (window.packing_stack.length > 0){
    var to_pack = window.packing_stack.splice(0, 1)[0];
    grid.append(to_pack).packery('appended', to_pack);
  } else {
    packing = false;
  }
  grid.imagesLoaded(function(){
    grid.packery();
  });
}

function packingFinished(){
  packing = false;
}

function submitNewsletter(e){
  if (e.keyCode == 13){
    $('#newsletter_form').submit();
  }
}

function toggleNewsletter(){
  var form = $('#newsletter_form');
  form.toggleClass('open');
  if (form.hasClass('open')){
    form.find('input').html('').focus();
  }
}

function setupMircostorySwipe(){
  if ($('#mobile_trigger').width() == 0){
    return;
  }

  project_microstories = $('#project_microstories');
  project_microstories_swipe = $('#project_microstories_swipe');
  var n = $('.microstory').length;
  if (n > 1){
    project_microstories
      .on('touchstart', startMicrostorySwipe)
      .on('touchmove', microstorySwipe)
      .on('touchend', endMicrostorySwipe);
    project_microstories_swipe
      .data('n', n)
      .data('i', 0);
    var pagination = $('<div id="project_microstories_pagination">');
    var dots       = $('<div id="project_microstories_dots">');
    var page       = $('<div class="caption">1 / ' + n + '</div>');
    dots.append(page);
    for (var i = 0; i < n; i++){
      dots.append($('<div class="microstory_dot">'));
    }
    project_microstories_swipe.data('page', page);
    project_microstories.append(pagination, dots);
    project_microstory_dots = $('.microstory_dot');
    project_microstory_dots.eq(0).addClass('selected');
    project_microstory_dots.on('click', showMicrostorySlide);
    project_microstory_dots.each(function(i){ $(this).data('i', i); });
  }
}

function startMicrostorySwipe(e){
  project_microstories_swipe
      .data('startx', e.originalEvent.touches[0].clientX)
      .data('x', project_microstories_swipe.position().left);
}

function showMicrostorySlide(){
  var dot = $(this);
  var i   = dot.data('i');
  project_microstories_swipe.css('left', -i * project_microstories.width());
  project_microstories_swipe.data('i', i);
  project_microstory_dots.removeClass('selected');
  dot.addClass('selected');
  project_microstories_swipe.data('page').html( (i + 1) + ' / ' + dot.data('n'));
}

function endMicrostorySwipe(e){
  var  x   = project_microstories_swipe.data('lastx');
  var dx   = project_microstories_swipe.data('startx') - x;
  var  i   = project_microstories_swipe.data('i');
  var  n   = project_microstories_swipe.data('n');
  var  w   = project_microstories.width();
  var snap = true;
  if (i < n - 1 && dx > 0 || i > 0 && dx < 0){
    if (dx > 0 && dx > w * 0.3){
      i++;
      project_microstories_swipe.css('left',  -i * w);
      project_microstories_swipe.data('i', i);
      snap = false;
    }
    if (dx < 0 && -dx > w * 0.3){
      i--;
      project_microstories_swipe.css('left',  -i * w);
      project_microstories_swipe.data('i', i);
      snap = false;
    }
  }
  if (snap){
    project_microstories_swipe.css('left', project_microstories_swipe.data('x'));
  } else {
    project_microstory_dots.removeClass('selected');
    project_microstory_dots.eq(i).addClass('selected');
    project_microstories_swipe.data('page').html( (i + 1) + ' / ' + n);
  }
}

function microstorySwipe(e){
  var  x = e.originalEvent.touches[0].clientX;
  var dx = project_microstories_swipe.data('startx') - x;
  var  i = project_microstories_swipe.data('i');
  var  n = project_microstories_swipe.data('n');

  project_microstories_swipe.data('lastx', x);
 
  if (i < n - 1 && dx > 0 || i > 0 && dx < 0){
    project_microstories_swipe.css('left', project_microstories_swipe.data('x') - dx);
  }
}

function setupProjects(){
  projects_search_field = $('#projects_search_field');
  projects_search_field_input = $('#projects_search_field input');
  $('#projects_filter_toggle').on('click', toggleProjectFilters);
  $('#close_project_filters').on('click', closeProjectFilters);
  $('.project_filter').on('click', applyProjectFilters);
  $('.project_sort').on('click', applyProjectFilters);
  $('#projects_search_toggle').on('click', toggleProjectsSearch);
  projects_search_field.on('keyup', searchProjects);
}

function searchProjects(e){
  var val = projects_search_field_input.val().trim();

  if (val.length > 2){
    if (!$('#projects').hasClass('loading')){
      applyProjectFilters();
    }
  } else {
    $.ajax({url: $('#project_filters').data('url'),
      type: 'get',
      complete: removeLoader,
      headers:{
        Accept: 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript'
      }
    });  
  }
}

function toggleProjectsSearch(){
  projects_search_field.toggleClass('open');
  if (projects_search_field.hasClass('open')){
    projects_search_field.find('input').focus().html('');
  }
}

function setupSlideshow(){
  if ($('.gm_slideshow').length > 0){
    $('.gm_slideshow').gmSlideshow();
    $(window).on('keydown', slideshowKeyboardShortcuts);
    $('.gm_slideshow_next').on('click', nextSlide);
    $('.gm_slideshow_previous').on('click', previousSlide);
  }
}

function setupSort(){
  $('.sort_by:not(.project_sort)').on('click', applySort);
}

function applySort(){
  var sort = $(this);
  var data = {}
  
  data['sort_by'] = sort.data('field');
  data['direction'] = 'desc'
  if (sort.hasClass('desc')){
    data['direction'] = 'asc';
  } 
  var list = sort.closest('.ruled_list');

  list.addClass('loading');
  
  $.ajax({url: list.data('url'),
    data: data, 
    type: 'get',
    complete: removeLoader,
    headers:{
      Accept: 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript'
    }
  });  
}

function removeLoader(){
  $('.loading').removeClass('loading');
}

function setupGoogleMaps(){
  $('.google_map').each(setupGoogleMap);
}

function setupGoogleMap(){
  var container         = $(this);
  var lat               = container.data('latitude') * 1;
  var long              = container.data('longitude') * 1;
  var location          = new google.maps.LatLng(lat, long);
  var google_map_object = new google.maps.Map(container[0], {
    center: location,
    zoom: 13,
    draggable: false,
    scrollwheel: false,
    mapTypeId: google.maps.MapTypeId.TERRAIN,
    disableDefaultUI: true,
    mapTypeId: 'Styled'
  });
  
  google_map_object.mapTypes.set('Styled', new google.maps.StyledMapType([
    {
      "stylers": [
        { "saturation": -100 },
        { "visibility": "simplified" },
        { "gamma": 1 }
      ]
    },
    {
      elementType: 'labels',
      stylers: [{visibility: 'off'}]
    } ], { name: 'Styled' })  
  );  

  var marker = container.data('marker');

  var gmarker = new google.maps.Marker({
    position: location,
    map: google_map_object,
    icon: marker ? marker : '/map_marker.svg'
  });
  gmarker.addListener('click', function(){ window.open('https://maps.google.com/maps?q=' + lat + ',' + long + '&z=16', '_blank'); });
}

function applyProjectFilters(){
  var filter  = $(this);
  var filters = { sector: [], service: [], status: [] };
  var data    = {};
  var search  = projects_search_field_input.val().trim();

  filter.toggleClass('selected');
  $('.project_filter.selected').each(function(){
    var f = $(this);
    filters[f.data('kind')].push(f.data('id'));
  });

  data['direction'] = 'desc'
  if (filter.hasClass('desc')){
    data['direction'] = 'asc';
  } 
  data['sort_by'] = filter.data('field');
  data['filters'] = filters;

  if (projects_search_field.hasClass('open') && search.length > 0){
    data['search'] = search;
  }
  
  $('#projects, #projects_archive').addClass('loading');
    
  $.ajax({url: $('#project_filters').data('url'),
    data: data, 
    type: 'get',
    complete: removeLoader,
    headers:{
      Accept: 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript'
    }
  });  
}

function toggleProjectFilters(){
  $('#projects_filter_toggle').toggleClass('selected'); 
  if ($('#projects_filter_toggle').hasClass('selected')){
    openProjectFilters();
  } else {
    closeProjectFilters();
  }
}

function openProjectFilters(){
  $('#projects_filter_toggle').addClass('selected');
  $('#project_filter_selections').slideDown(200);
}

function closeProjectFilters(){
  $('#projects_filter_toggle').removeClass('selected');
  $('#project_filter_selections').hide();
}

function nextSlide(){
  $(this).closest('.gm_slideshow').gmNextSlide();
}
function previousSlide(){
  $(this).closest('.gm_slideshow').gmPreviousSlide();
}

function slideshowKeyboardShortcuts(e){
  if (e.keyCode == 37){
    $('.gm_slideshow').gmPreviousSlide();   
  }
  if (e.keyCode == 39){
    $('.gm_slideshow').gmNextSlide();   
  }
}

// Videos

function setupVideos(){
  videos = $('video:not(.manual)');
  video_containers = $('.video_container:not(.iframe)');
  vimeo_videos = $('.video_container.iframe');
  video_containers.on('click', toggleVideoState);

  if (vimeo_videos.length > 0){
    vimeo_videos.fitVids();
    vimeo_videos.each(function(){
      var video = $(this);
      var player = new Vimeo.Player(video.find('iframe')[0]);
      player.setVolume(0);
    });
    $(window).on('resize', function(){ vimeo_videos.fitVids();  });
  }
  if (videos.length > 0 || vimeo_videos.length > 0){
    $(window).on('scroll', playPauseVideo);
    $(window).on('mousemove', recordLastMouseMove);

    recordLastMouseMove();
    
    video_containers.each(function(){
      var video = $(this);
      video.data('video', video.find('video')[0]);
    });

    setInterval(checkVideoPauseShow, 300);
  }
}

function recordLastMouseMove(){
  last_move = new Date();
}

function checkVideoPauseShow(){
  var now = new Date();
  if (now - last_move > 1300){
    if (!video_buttons_hidden){
      video_containers.addClass('hide_button');
      video_buttons_hidden = true;
    }
  } else {
    if (video_buttons_hidden){
      video_containers.removeClass('hide_button');
      video_buttons_hidden = false;      
    }
  }
}

function toggleVideoState(){
  var video_container = $(this);
  var video           = video_container.find('video');
  video_container.toggleClass('paused');
  video_container.addClass('manual');
  video.prop('loop','');

  if (video_container.hasClass('paused')){
    video[0].pause();
  } else {
    video[0].volume = 0.7;
    video[0].play();
  }
}

function playPauseVideo(){
  video_containers.each(checkPlayPauseVideo);
  vimeo_videos.each(checkPlayPauseVimeoVideo);
}


function checkPlayPauseVimeoVideo(){
  scroll_top = $(document).scrollTop();
  vh         = $(window).height();
  s0         = scroll_top;
  s1         = scroll_top + vh;

  var video   = $(this);
  if (video.hasClass('manual')){
    return;
  }
  var visible = checkVisibility(video);
  video_e = new Vimeo.Player(video.find('iframe')[0]);

  if (visible){
    video_e.getPaused().then(function(paused){
      if (paused){
        video_e.play();
      }
    });
  } else {
    video_e.getPaused().then(function(paused){
      if (!paused){
        video_e.pause();
      }
    });
  }
}

function checkPlayPauseVideo(){
  scroll_top = $(document).scrollTop();
  vh         = $(window).height();
  s0         = scroll_top;
  s1         = scroll_top + vh;

  var video   = $(this);
  var visible = checkVisibility(video);
  video_e = video.data('video');

  if (visible && video_e.paused && !video.hasClass('manual')){
    video_e.volume = 0;
    video_e.play();
  }
  if (!visible && !video_e.paused){
    video_e.pause();   
    video.addClass('paused');
  }
}

function checkVisibility(element){
  var h       = element.outerHeight();
  var y0      = element.offset().top;
  var y1      = y0 + h;
  var visible = false;

  if (y1 > s0 && y1 < s1){
    visible = true;
  }

  if (y0 > s0 && y0 < s1){
    visible = true;
  }

  if (y0 <= s0 && y1 >= s1){
    visible = true;
  }
  return visible;
}

// Infinite scroll

function setupInfiniteScroll(){
  infinite_scroll_content = $('.infinite_scroll_content');
  if(infinite_scroll_content.length){
    infinite_scroll_pages = infinite_scroll_content.data('pages') * 1;
    infinite_scroll_url = infinite_scroll_content.data('url');
    infinite_scroll_page = infinite_scroll_content.data('page') * 1;
    infinite_scroll_trigger_percentage = infinite_scroll_content.data('trigger');
    if (!infinite_scroll_trigger_percentage){
      infinite_scroll_trigger_percentage = 0.7;
    }
    $(document).on('scroll', infiniteScroll);
    infiniteScrollFillIn();
  }
}

function infiniteScrollFillIn(){ 
  if($('body').height() < $(window).height() * 1.5 && infinite_scroll_page < infinite_scroll_pages){
    infiniteScroll();
    $('body').trigger('gm:infinite_scroll');
  } else {
    $('body').trigger('gm:infinite_scroll_finish');
  }
}

function infiniteScroll(){ 
  if( ($(document).height() - $(document).scrollTop() - $(window).height() < $(window).height() * infinite_scroll_trigger_percentage ) &&
      (infinite_scroll_page < infinite_scroll_pages) &&
       !infinite_scroll_loading){  
    infinite_scroll_page += 1;
    infinite_scroll_loading = true;
    
    var data_hash = { page: infinite_scroll_page };
    $('body').trigger('gm:infinite_scroll_start');
    $.ajax({ url: infinite_scroll_url,
              data: data_hash,
              headers:{
                Accept: 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript'
              },
              complete: function(){
                          infinite_scroll_loading = false;
                          infiniteScrollFillIn();
                        } } );
  }
}